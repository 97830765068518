import React from 'react';
import Peticion from '../utilidades/peticion';
import '../index.css';
class comp extends React.Component {
constructor(props) {
	  super(props);
	  //un json de las distintas variables del componente
	  this.state = { value: '',
	  				 resultado: []	
	  				  }
	  //por cosos del react hay que bindear a si mismo a los eventos lel

}	
componentDidMount(){
	var respuesta=[];
    var consuljson={
            IDALUMNO: this.props.IDALUMNO,
            VALI: this.props.VALI
                  }              
	Peticion.post("bd/superquery",consuljson).then(res=>{
    var notasalumno="";
    //si la peticion no me devuelve un arrego, no puedo usar map. ( por ejemplo el findOne del sequelizer)

    res.data.map(elem =>{
      console.log(elem);
    });
      if (res.data.length>0) 
        {
        this.setState({resultado: res.data});
        console.log("EL RESULTADO ES --->",res.data);
        }
      else alert('NO HAY NADA..');
      if(res.status){
        console.log("ok ", res.data)
      }
      else{
        console.log("error ", res.data)
      }
    })
	}


//PROPIEDADES (.props  y se heredan desde donde lo mandamos a llamar ) DISTINTO DE LOS ESTADOS (.state que se estan definiendo arriba )

render() 
{
var self=this;
		
return (
    <div className="divtienetabla">  
      <table className="latabla">
            <tr className="tablacabeza">
            <td><strong>NOMCURSO</strong></td>
            <td><strong>NOTA</strong></td>
            </tr>
      {
            self.state.resultado.map(elem =>{
            return(                    
                      <tr className="tablafila" key={elem.DESCRIPCION}>
                        <td>{elem.DESCRIPCION}</td>
                        <td>{elem.NOTA}</td>
                      </tr>
                  );
              })
            }
  </table>
    </div>
  );
}


}

comp.defaultProps = {
IDCLI:"111",
VALI:"PERRO"}



export default comp;






