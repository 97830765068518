import React from 'react';
import Peticion from '../utilidades/peticion';
import '../index.css';
class comp extends React.Component {
constructor(props) {
	  super(props);
	  //un json de las distintas variables del componente
	  this.state = { value: '',
	  				 resultado:{ 
                          DOCALUMNO:""
                        }
	  				  }
	  //por cosos del react hay que bindear a si mismo a los eventos lel

}	
componentDidMount(){
	var respuesta=[];
    var consuljson={
            IDALUMNO: this.props.IDALUMNO,
            VALI: this.props.VALI
                  }              
	Peticion.post("bd/datosalumno",consuljson).then(res=>{
    //si la peticion no me devuelve un arrego, no puedo usar map. ( por ejemplo el findOne del sequelizer)

      if (res.data!='') 
        {
        this.setState({resultado: res.data});
        console.log("EL RESULTADO ES --->",res.data);
        }
      else alert('NO HAY NADA..');
      if(res.status){
        console.log("ok ", res.data)
      }
      else{
        console.log("error ", res.data)
      }
    })
	}


//PROPIEDADES (.props  y se heredan desde donde lo mandamos a llamar ) DISTINTO DE LOS ESTADOS (.state que se estan definiendo arriba )

render() 

{
var self=this;		
return (
    <div className="datos">  
    <label className="labeltitulo">NOMBRE DEL ALUMNO: </label>
    <label>{self.state.resultado.NOMALUMNO}</label><br/>
    <label className="labeltitulo">DOCUMENTO: </label>
    <label>{
    self.state.resultado.DOCALUMNO.length == 8 ? <spam>DNI </spam> : <spam>C.E </spam>
    }</label>
     <label>{self.state.resultado.DOCALUMNO}</label>

    </div>
  );
}


}

export default comp;

