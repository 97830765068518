import logo from './logo.svg';
import './App.css';
//Componentes deben ir la primera letra con mayusculas
//import Consul from './componentes/consultaservidor';


function App() {
  return (
    <div className="App">
      <header className="App-header">
         {/*<img src={logo} className="App-logo" alt="logo" />*/} 
        <p>   
        </p>
        <p> "PAGINA" </p>
      </header>
    </div>
  );
}

export default App;