import React from 'react';
import Peticion from '../utilidades/peticion';

class comp extends React.Component {
constructor(props) {
	  super(props);
	  //un json de las distintas variables del componente
	  this.state = { counter: 0,
	  				 value1: '',
	  				 value2: '',
	  				 resultado: []	
	  				  }
	  //por cosos del react hay que bindear a si mismo a los eventos lel
	  this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleClick = this.handleClick.bind(this);

}
 handleChange(event) {   
	switch (event.target.id) {
		case '1':
				this.setState({value1: event.target.value}); 
				break;
		case '2':
				this.setState({value2: event.target.value}); 
				break;
		}	
  }
 handleSubmit(event) {
    alert('escribiste  ' + this.state.value);
    event.preventDefault();
  }
  handleClick(event) {
  	if (this.state.value=='') {alert('PON ALGO');this.setState ({resultado:[]}); return; }
  	var consuljson={
            IDALUMNO: this.state.value1,
            VALI: this.state.value2
        					}
  }



render() 
{
//haremos cosas raras para poder mapear el estado que generamos arriba
//con esto tendremos acceso a los que esta arriba por que no tenemos acceso al this
//se recomienda usar el self por que el this puede dar problemas
var self=this;
	
	return(
	<div style={{color: "white"}}>
			<label>
	          Nombre:
	          <input id="1" type="text" value1={this.state.value} onChange={this.handleChange} /> 
	          <input id="2" type="text" value2={this.state.value} onChange={this.handleChange} />  	          
	          <button onClick={this.handleClick}>
		      		boton
		      	</button>

	     </label>
	          {/* <input type="submit" value="Submit" />  */}  
      	 {/* </form>	 */} 
      
	</div>)}


}
export default comp;
