import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import Consulta1 from './componentes/consulta1';
import Consulparam from './componentes/consulparam';
//import reportWebVitals from './reportWebVitals';

import { render } from "react-dom";
import {BrowserRouter,Routes,Route,} from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById('root'));
// import your route components too

render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}></Route> 
      <Route path="/consulta1" element={<Consulta1/>}></Route>
      <Route path="/consulta2" element={<Consulparam/>}></Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);

