import * as React from "react";
import { useSearchParams } from "react-router-dom";
import Tablanotas from './tablanotas';
import Datosalumno from './datosalumno';
import '../index.css';
var logo =require("../assets/logo.png");
//var fondo =require("../assets/ccl.jpeg");


function App() {
  let [searchParams, setSearchParams] = useSearchParams();
  console.log(searchParams)
  console.log(typeof searchParams.get('p1'))
  console.log(typeof searchParams.get('p2'))
  return (
        <div className="divmegacontenedor">
          <div className="divcontenedor">
            <div className="divcabeza"><img className="imagenlogo" src={logo} /></div>
            <div className="divcontenido">
              <Datosalumno IDALUMNO={searchParams.get('p1')}  VALI={searchParams.get('p2')}/>
              <Tablanotas IDALUMNO={searchParams.get('p1')}  VALI={searchParams.get('p2')}/>
            </div>
            <div className="divfin"></div>
          </div>
        </div>

  );
}
export default App;

