import { promisify } from 'util';

const axios1=require('axios');
const URL='https://certificadosccvp.com:3022';
const axios =axios1.create({
  baseURL: URL
  //headers: {'Authorization': sessionstorage.getItem("token")}
});
//import download from 'downloadjs';


async function get(endpoint, params){

  
  return new Promise(resolve => {
      axios.get(URL+"/"+endpoint, {
        params: params
      })
      .then(function (response) {
        console.log(response);
        resolve({status:1, data:response.data})
      })
      .catch(function (error) {
      console.log(error);
        resolve({status:0, data:error})
      })
    });
}

async function post(endpoint, params){
  return new Promise(resolve => {
      axios.post(URL+"/"+endpoint, params)
    .then(function (response) {
        console.log(response);
        resolve({status:1, data:response.data})
    })
    .catch(function (error) {
      console.log(error);
      resolve({status:0, data:error})
    });
    });
}





var app={post:post, get:get, URL:URL};
export default app;

